<template>
  <b-container>
    <b-row>
      <b-col>
        <h1 class="pt-4 pb-4 text-center">Razvoj CC gibanja v Sloveniji</h1>
      </b-col>
    </b-row>
    <b-row class="mb-5 justify-content-center text-center text-md-left">
      <b-col lg="8">
        <p>
          Organizacija je bila ustanovljen leta 2001 in konec leta 2002 je že izšla prva različica
          CC licenc. Inštitut za intelektualno lastnino (IPI) je v Sloveniji že leta 2005 poskrbel
          za prevod in prilagoditev CC licenc v slovenski jezik in slovenski pravni red. Skupaj z
          laboratorijem za digitalne medije pri KUD France Prešeren –
          <a href="http://ljudmila.org/">Ljudmila</a>, sta 29. oktobra 2005 na
          <a href="http://ustvarjalnagmajna.si/node/69">Festivalu ustvarjalnosti in svobodne
            kulture</a> lansirala slovensko različico CC licenc. 5. decembra naslednjega leta je
          potekal <a href="http://ustvarjalnagmajna.si/node/220">drugi mednarodni festival</a> pod
          geslom “Ustvarjalna gmajna!”. IPI od oktobra 2005 skrbi za pravni vidik licenc.
        </p>
        <p>
          Leta 2011 je z različico 4.0 CC licenc prišlo do internacionalizacije licenc, kar pomeni,
          da licenc ni več treba prilagajati posameznim pravnim redom, ampak obstaja enotna licenca,
          ki velja za cel svet. Za prevod in pravni pregled zadnje različice licenc je poskrbel IPI.
        </p>
        <p>
          Leta 2019 je prišlo do reorganizacije CC, ki je sedaj po novem
          <a href="https://network.creativecommons.org/">Creative Commons Global Network</a> (CCGN),
          mreža, v katero se lahko včlanijo posamezniki in institucije. Znotraj CCGN delujejo
          posamezni chapter-ji, med katerimi od 6. maja 2019 obstaja tudi
          <a href="https://www.ipi.si/cc-chapter-slovenija/">slovenski chapter</a>, katerega vodja
          je Žiga Vrtačič in predstavnica Maja Bogataj Jančič.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Zgodovina',
};

</script>

<style scoped lang="scss">

p {
  margin-bottom: 1rem;
}

a {
  color: black;
  text-decoration: underline;

  &:hover {
    color: #c8eb00;
  }
}

</style>
